import { Box } from '@mui/material';
import { CustomStack } from 'components/CustomStack';
import { AppWrapper } from 'components/AppWrapper';
import { AppRoutes } from 'components/AppRoutes';

export const App = () => {
	return (
		<AppWrapper>
			<Box
				minHeight="100vh"
				justifyContent="center"
				margin="auto"
				marginTop="3rem"
				minWidth="300px"
				maxWidth="1000px">
				<CustomStack>
					<AppRoutes/>
				</CustomStack>
			</Box>
		</AppWrapper>
	);
};
