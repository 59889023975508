import { Route, Routes } from 'react-router-dom';
import { Home } from 'pages/Home';
import { Callback } from '/src/pages/Callback';
import { PaymentRecieved } from '/src/pages/PaymentRecieved';
import { Credits } from 'pages/Credits';
import NoPage from '/src/pages/NoPage';
import { Faq } from 'pages/Faq';
import { Terms } from 'pages/Terms';
import { Profile } from 'pages/Profile';
import { Contact } from 'pages/Contact';
import { MyImages } from 'pages/MyImages';
import { AboutUs } from 'pages/AboutUs';
import { Outlet } from 'react-router-dom';
import { Referal } from 'pages/Referal';
import { useSelector } from 'react-redux';

export const AppRoutes = () => {
	const isAuth = Boolean(useSelector((state) => state.global.token));
	const user = useSelector((state) => state.global.user);
	//const isAdmin = user.role = "Admin" || "Superadmin";
	return (
		<Routes>
			<Route
				path="/"
				element={<Outlet />}>
				<Route
					index
					element={<Home />}
				/>
				<Route
					path="redir"
					element={<Callback />}
				/>
				<Route
					path="paymentRecieved"
					element={<PaymentRecieved />}
				/>
				<Route
					path="priser"
					element={<Credits />}
				/>
				<Route
					path="faq"
					element={<Faq />}
				/>
				<Route
					path="terms"
					element={<Terms />}
				/>
				<Route
					path="profile"
					element={<Profile />}
				/>
				<Route
					path="images"
					element={<MyImages />}
				/>
				<Route
					path="contact"
					element={<Contact />}
				/>
				<Route
					path="about"
					element={<AboutUs />}
				/>
				<Route
					path="referal"
					element={<Referal />}
				/>
				<Route
					path="*"
					element={<NoPage />}
				/>
			</Route>
		</Routes>
	);
};
