import { HeartBroken, Favorite } from "@mui/icons-material";
import { Box } from "@mui/material";

export const RatingHeart = ({ value, rating, onClick }) => {
  return (
    <Box
      sx={{ cursor: "pointer" }}
      onClick={() => {
        onClick ? onClick(value) : null;
      }}
    >
      {rating >= value ? (
        <Favorite fontSize="large" sx={{ color: "#f00" }} />
      ) : (
        <HeartBroken fontSize="large" sx={{ color: "#777" }} />
      )}
    </Box>
  );
};
