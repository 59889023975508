import { useState } from 'react';
import {
	Button,
	useTheme,
	Stack,
	useMediaQuery,
	IconButton,
	Box,
} from '@mui/material';
import { MenuRounded, Close, Feedback } from '@mui/icons-material';
import { styled } from '@mui/material/styles';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { startLogin } from './ApiActions';
import FlexBetween from './FlexBetween';
import { setIsWriting, setIsFinished, setIsAborted, setFeedbackOpen } from 'state';

export const Navbar = () => {
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const theme = useTheme();
	const isNonMobileScreens = useMediaQuery('(min-width: 1000px)');
	const [isMobileMenuToggled, setIsMobileMenuToggled] = useState(false);
	const hasProfile = Boolean(
		useSelector((state) => state.global.profile) !== null
	);
	const profile = useSelector((state) => state.global.profile);
	const user = useSelector((state) => state.global.user);

	const Item = styled(Button)(({ theme }) => ({
		color: '#fff',
		textShadow: '1px 1px 2px #000',
		border: `1px solid ${'transparent'}`,
		'&:hover': {
			backgroundColor: theme.palette.grey[50],

			border: `1px solid ${theme.palette.primary.main}`, // Highlight border on hover
		},
		borderRadius: theme.shape.borderRadius, // Rounded corners for modern look
		padding: '8px 24px', // Comfortable padding
		margin: '0 12px', // Space between buttons
		textTransform: 'none', // Keep text casing
		boxShadow: 'none', // No shadow for a flatter look
		fontSize: '1.2rem', // Readable font size
	}));
	const doStartLogin = () => {
		startLogin();
		setIsMobileMenuToggled(!isMobileMenuToggled);
	};
	const handleNavigate = (location) => {
		navigate(location);
		setIsMobileMenuToggled(false);
	};
	const handleHomeClick = () => {
		navigate('/');
		setIsMobileMenuToggled(false);
		dispatch(setIsAborted({ isAborted: false }));
		dispatch(setIsWriting({ isWriting: false }));
		dispatch(setIsFinished({ isFinished: false }));
	};

	return (
		<header>
			<Stack
				direction="row"
				justifyContent="space-between"
				alignItems="center" // Center items vertically
				padding="0.2rem"
				sx={{
					width: '100vw',
					overflow: 'hidden',
					backgroundColor: '#00000020',
				}}>
				{!isNonMobileScreens ? (
					<Item onClick={() => dispatch(setFeedbackOpen({ open: true }))}>
						<Feedback />
					</Item>
				) : (
					<></>
				)}
				<img
					src={
						isNonMobileScreens
							? 'https://pixygontech.b-cdn.net/LLLogoWide.png'
							: 'https://pixygontech.b-cdn.net/LLLogo512.png'
					}
					alt="Logo"
					width={isNonMobileScreens ? '192' : '40'}
					height="auto"
					margin="4px"
					ml="1rem"
					style={{ cursor: 'pointer', marginLeft: '20px' }}
					onClick={() => handleHomeClick()}
				/>
				{isNonMobileScreens ? (
					<>
						<Box>
							{user ? (
								<Item onClick={() => navigate('/images')}>Mine bilder</Item>
							) : (
								<></>
							)}
							<Item onClick={() => navigate('/faq')}>FAQ</Item>
							<Item onClick={() => navigate('/priser')}>Priser</Item>
							<Item onClick={() => dispatch(setFeedbackOpen({ open: true }))}>
								Feedback
							</Item>
						</Box>
						<Stack
							direction="row"
							spacing={2}
							mr="1rem">
							{user ? (
								<Item
									sx={{
										cursor: 'pointer',
										fontSize: '1rem', // Uniform font size
									}}
									onClick={() => {
										navigate('/profile');
									}}>
									{hasProfile ?? <>{profile.credits} mynter</>}
									Hei {user.given_name}!
								</Item>
							) : (
								<Box onClick={doStartLogin}>
									<vipps-mobilepay-button
										type="button"
										brand="vipps"
										language="no"
										variant="primary"
										rounded="true"
										verb="login"
										stretched="false"
										branded="true"
										loading="false"
									/>
								</Box>
							)}
						</Stack>
					</>
				) : isMobileMenuToggled ? (
					<Box>
						<IconButton
							onClick={() => setIsMobileMenuToggled(!isMobileMenuToggled)}
							sx={{
								color: theme.palette.primary.main,
							}}>
							<Close />
						</IconButton>
					</Box>
				) : (
					<Box>
						<IconButton
							onClick={() => setIsMobileMenuToggled(!isMobileMenuToggled)}
							sx={{
								color: theme.palette.primary.main,
							}}>
							<MenuRounded />
						</IconButton>
					</Box>
				)}
			</Stack>

			{/* MOBILE NAV */}
			{!isNonMobileScreens && isMobileMenuToggled && (
				<Box
					class=".transparent"
					position="fixed"
					right="0"
					bottom="0"
					top="70px"
					height="270px"
					zIndex="10"
					minWidth="100%"
					padding="1rem 10%">
					{/* MENU ITEMS */}
					<FlexBetween
						display="flex"
						flexDirection="column"
						justifyContent="center"
						alignItems="center"
						gap="1rem"
						sx={{ backgroundColor: '#00000020' }}>
						{user && (
							<Item onClick={() => handleNavigate('/images')}>Mine bilder</Item>
						)}
						<Item onClick={() => handleNavigate('/faq')}>FAQ</Item>
						<Item onClick={() => handleNavigate('/priser')}>Priser</Item>
						<Item onClick={() => dispatch(setFeedbackOpen({ open: true }))}>
							Feedback
						</Item>
						{user ? (
							<Item
								sx={{
									cursor: 'pointer',
									fontSize: '1rem', // Uniform font size
								}}
								onClick={() => handleNavigate('/profile')}>
								Hei {user.given_name}!
							</Item>
						) : (
							<img
								src="./LoginPill.svg"
								alt="Login"
								onClick={doStartLogin}
								style={{
									maxWidth: '200px',
									height: 'auto',
								}}
							/>
						)}
					</FlexBetween>
				</Box>
			)}
		</header>
	);
};
