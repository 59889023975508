import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

export const feedbackApi = createApi({
	reducerPath: 'feedbackApi',
	baseQuery: fetchBaseQuery({ baseUrl: import.meta.env.VITE_BASE_URL }),
	endpoints: (build) => ({
		getFeedback: build.query({
			query: ({ token, projectId }) => ({
				url: `feedback?projectId=${projectId}`,
				method: 'GET',
				headers: {
					Authorization: `Bearer ${token}`,
					'Content-Type': 'application/json',
				},
				providesTags: ['Feedback'],
			}),
		}),
		postCreateFeedback: build.mutation({
			query: ({ token, feedback }) => ({
				url: `feedback`,
				method: 'POST',
				body: { feedback },
				headers: {
					Authorization: `Bearer ${token}`,
					'Content-Type': 'application/json',
				},
				providesTags: ['Feedback'],
			}),
		}),
	}),
});

export const { useGetFeedbackQuery, usePostCreateFeedbackMutation } =
	feedbackApi;
