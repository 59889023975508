import { CssBaseline, Box } from "@mui/material";
import { ThemeProvider } from "@mui/material/styles";
import theme from "theme";
import { BrowserRouter } from "react-router-dom";
import { HelmetProvider } from "react-helmet-async";
import { Navbar } from "components/Navbar";
import { FeedbackPopup } from "popups/feedbackPopup";

export const AppWrapper = ({ bgImage, children }) => {
  const helmetContext = {};
  return (
    <div className="app">
      <HelmetProvider context={helmetContext}>
        <BrowserRouter>
          <ThemeProvider theme={theme}>
            <CssBaseline />
            <Navbar />
            <Box
              height="100vh"
              justifyContent="center"
              minWidth="300px"
              sx={{
                backgroundSize: "cover",
                backgroundPosition: "center",
                backgroundRepeat: "no-repeat",
                backgroundImage: bgImage,
              }}
            >
              {children}
            </Box>
            <FeedbackPopup />
          </ThemeProvider>
        </BrowserRouter>
      </HelmetProvider>
    </div>
  );
};
