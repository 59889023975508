import { createSlice } from '@reduxjs/toolkit';

const initialState = {
	token: null,
	user: null,
	profile: null,
	idempotencyKey: '',
	reference: '',
	paid: false,
	phoneNumber: '',
	type: 'Interior',
	room: 0,
	designStyles: 0,
	prompt: '',
	product: '',
	image: null,
	actualImage: null,
	maskImage: null,
	actualMaskImage: null,
	hasDrawn: false,
	hasImage: false,
	finalizedImage: null,
	errorMessage: '',
	isWriting: false,
	isFinished: false,
	isAborted: false,
	redesignMode: 1,
	partnerEmail: '',
	promoCode: '',
	testerMode: false,
	color: 0,
	material: 0,
	materialType: 0,
	referalId: '',
	feedbackOpen: false
};

export const globalSlice = createSlice({
	name: 'global',
	initialState,
	reducers: {
		setLogin: (state, action) => {
			state.token = action.payload.token;
		},
		setUser: (state, action) => {
			state.user = action.payload.user;
			state.phoneNumber = action.payload.user.phone_number;
		},
		setProfile: (state, action) => {
			state.profile = action.payload.profile;
		},
		setTransaction: (state, action) => {
			state.idempotencyKey = action.payload.idempotencyKey;
			state.reference = action.payload.reference;
			state.paid = false;
		},
		setLogout: (state) => {
			state.token = null;
			state.user = null;
			state.profile = null;
		},
		updateTransaction: (state, action) => {
			if (
				state.idempotencyKey === action.payload.idempotencyKey &&
				state.reference === action.payload.reference
			) {
				state.paid = action.payload.paid;
			}
		},
		finalizeTransaction: (state, action) => {
			state.idempotencyKey = '';
			state.reference = '';
			state.product = '';
			state.paid = false;
		},
		setType: (state, action) => {
			state.type = action.payload.type;
		},
		setRoom: (state, action) => {
			state.room = action.payload.room;
		},
		setPrompt: (state, action) => {
			state.prompt = action.payload.prompt;
		},
		setDesignStyle: (state, action) => {
			state.designStyles = action.payload.designStyles;
		},
		setColor: (state, action) => {
			state.color = action.payload.color;
		},
		setMaterial: (state, action) => {
			state.material = action.payload.material;
		},
		setMaterialType: (state, action) => {
			state.materialType = action.payload.materialType;
		},
		setProduct: (state, action) => {
			state.product = action.payload.product;
		},
		setImage: (state, action) => {
			state.image = action.payload.image;
			state.actualImage = action.payload.actualImage;
			state.hasImage = true;
		},
		setMaskImage: (state, action) => {
			state.maskImage = action.payload.maskImage;
			state.actualMaskImage = action.payload.actualMaskImage;
			state.hasDrawn = true;
		},
		setClearImage: (state, action) => {
			state.maskImage = null;
			state.actualMaskImage = null;
			state.hasDrawn = false;
		},
		removeImage: (state, action) => {
			state.image = null;
			state.hasImage = false;
			state.actualImage = null;
			state.finalizedImage = null;
			state.maskImage = null;
			state.actualMaskImage = null;
			state.hasDrawn = false;
			state.redesignMode = 0;
			state.room = 1;
			state.material = 1;
			state.designStyles = 1;
			state.prompt = "";
			state.type = "Interior";
		},
		setFinalizedImage: (state, action) => {
			state.finalizedImage = action.payload.image;
		},
		setErrorMessage: (state, action) => {
			state.errorMessage = action.payload.errorMessage;
		},
		setIsWriting: (state, action) => {
			state.isWriting = action.payload.isWriting;
		},
		setIsFinished: (state, action) => {
			state.isFinished = action.payload.isFinished;
		},
		setIsAborted: (state, action) => {
			state.isAborted = action.payload.isAborted;
		},
		setRedesignMode: (state, action) => {
			state.redesignMode = action.payload.redesignMode;
		},
		setPartnerEmail: (state, action) => {
			state.partnerEmail = action.payload.partnerEmail;
		},
		setPromoCode: (state, action) => {
			state.promoCode = action.payload.promoCode;
		},
		setTesterMode: (state, action) => {
			state.testerMode = action.payload.testerMode;
		},
		setReferalId: (state, action) => {
			state.referalId = action.payload.referalId;
		},
		setFeedbackOpen: (state, action) => {
			state.feedbackOpen = action.payload.open;
		}
	},
});

export const {
	setLogin,
	setUser,
	setProfile,
	setTransaction,
	updateTransaction,
	finalizeTransaction,
	setLogout,
	setType,
	setRoom,
	setPrompt,
	setDesignStyle,
	setProduct,
	setImage,
	removeImage,
	setClearImage,
	setFinalizedImage,
	setErrorMessage,
	setIsWriting,
	setIsFinished,
	setIsAborted,
	setRedesignMode,
	setPartnerEmail,
	setPromoCode,
	setTesterMode,
	setMaskImage,
	setColor,
	setMaterial,
	setMaterialType,
	setReferalId,
	setFeedbackOpen
} = globalSlice.actions;

export default globalSlice.reducer;
