import { ArrowDownward, ArrowDropDown } from '@mui/icons-material';
import {
	Button,
	TextField,
	InputAdornment,
	Box,
	Stack,
	Select,
	MenuItem,
	FormLabel,
	FormControlLabel,
	Checkbox,
	FormControl,
	InputLabel,
	Typography,
	useTheme,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import { useState } from 'react';

export const GlassBox = styled(Box)(({ theme }) => ({
	borderRadius: '15px',
	position: 'relative',
	backgroundColor: 'rgba(255, 255, 255, 0.1)',
	backdropFilter: 'blur(10px)',
	overflow: 'hidden',
	boxShadow: '0px 4px 4px #0004',
	'&:before': {
		content: '""',
		position: 'absolute',
		top: 0,
		left: 0,
		right: 0,
		bottom: 0,
		padding: '1px', // This padding ensures the gradient is just on the border
		background:
			'linear-gradient(172deg, #fffa 0%, #fff0 41%, #fff0 57%, #fff2 100%)', // Green gradient border
		borderRadius: 'inherit',
		WebkitMask:
			'linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0)',
		WebkitMaskComposite: 'xor',
		maskComposite: 'exclude',
		zIndex: -1, // Ensure it stays behind the content
	},
}));
export const CustomButton = styled(Button)(({ theme }) => ({
	color: theme.palette.text.dark,
	textTransform: 'none',
	gap: theme.spacing(4),
	background: theme.palette.gradientButton,
	borderRadius: '25px',
	padding: '.5rem',
	border: '1px solid #fff5',
	outline: 'none',
	transition: 'all 0.3s ease-in-out',
	display: 'inline-block',
	fontSize: '14px',
	fontWeight: '600',
	textAlign: 'center',
	cursor: 'pointer',
	'&:hover': {
		border: '1px solid #fff9',
		background: 'linear-gradient(30deg, #3472A277 0%, #4682B4cc 100%)',
	},
}));
export const CustomRowBox = styled(Box)(({ theme }) => ({
	display: 'flex',
	flexDirection: 'row',
	justifyContent: 'center',
}));
export const CustomColumnBox = styled(Box)(({ theme }) => ({
	display: 'flex',
	flexDirection: 'column',
	justifyContent: 'center',
}));
export const CustomStack = styled(Stack)(() => ({
	mt: '1rem',
	mb: '3rem',
	direction: 'column',
	justifyContent: 'center',
	alignItems: 'center',
	useFlexGap: true,
	flexWrap: 'wrap',
}));
export const CustomCheckbox = ({ label, value, onChange }) => {
	return (
		<FormControlLabel
			control={
				<Checkbox
					checked={value}
					onChange={onChange}
					inputProps={{ 'aria-label': 'controlled' }}
					sx={{
						color: '#fff',
						inputProps: { 'aria-label': 'controlled' },
						'&.Mui-checked': {
							color: '#fff', // Ensures the checkbox stays white when checked
						},
					}}
				/>
			}
			label={label}
		/>
	);
};
export const CustomInputTextField = ({
	label,
	onChange,
	adornment,
	placeholder,
	multiline,
	value,
	key,
}) => {
	const theme = useTheme();

	return (
		<Box
			width="100%"
			sx={{
				display: 'flex',
				alignItems: 'center',
				alignContent: 'center',
				gap: theme.spacing(2),
				margin: '0.5rem',
			}}>
			<TextField
				fullWidth
				key={key}
				label={label}
				multiline={multiline}
				onChange={onChange}
				placeholder={placeholder}
				variant="outlined"
				value={value}
				sx={{
					'& .MuiOutlinedInput-root': {
						// Target the root of the input for additional styles
						color: theme.palette.text.dark,
						'& fieldset': {
							borderColor: theme.palette.grey[50], // Example to customize border color
							backgroundColor: theme.palette.grey[5000],
							color: theme.palette.text.dark,
						},
						'&:hover fieldset': {
							borderColor: theme.palette.primary.main, // Customize border color on hover
							color: theme.palette.text.dark, // Use the primary text color from theme
						},
						'&.Mui-focused fieldset': {
							borderColor: theme.palette.primary.main, // Customize border color when focused
						},
					},
					'& .MuiInputLabel-root': {
						// Styles for the input label
						color: theme.palette.text.dark, // Use the primary text color from theme
					},
					'& .MuiInputAdornment-root': {
						// Styles for the input adornment
						color: theme.palette.text.dark, // Use the secondary text color from theme
					},
				}}
			/>
		</Box>
	);
};
export const CustomTextField = ({ text }) => {
	const theme = useTheme();
	return (
		<TextField
			fullWidth
			id="outlined-required"
			multiline
			sx={{
				typography: 'aiText',
				'& .MuiOutlinedInput-root': {
					// Target the root of the input for additional styles
					'& fieldset': {
						borderColor: theme.palette.grey[50], // Example to customize border color
						backgroundColor: theme.palette.grey[5000],
					},
					'&:hover fieldset': {
						borderColor: theme.palette.primary.main, // Customize border color on hover
						color: theme.palette.text.secondary, // Use the primary text color from theme
					},
					'&.Mui-focused fieldset': {
						borderColor: theme.palette.primary.main, // Customize border color when focused
					},
				},
				'& .MuiInputLabel-root': {
					// Styles for the input label
					color: theme.palette.text.primary, // Use the primary text color from theme
				},
				'& .MuiInputAdornment-root': {
					// Styles for the input adornment
					color: theme.palette.text.secondary, // Use the secondary text color from theme
				},
			}}
			defaultValue={text}></TextField>
	);
};
export const CustomDropdown = ({ value, onChange, options }) => {
	return (
		<GlassBox>
			<FormControl
				fullWidth
				variant="outlined"
				sx={{
					'& .MuiOutlinedInput-root': {
						color: '#fff', // White text color,
						'& input': {
							padding: '4px 7px',
						},
						'& fieldset': {
							border: 'none', // No border around the dropdown
						},
						'&:hover fieldset': {
							border: 'none', // No border on hover
						},
						'&.Mui-focused fieldset': {
							border: 'none', // No border when focused
						},
					},
				}}>
				<Select
					value={value}
					onChange={onChange}
					displayEmpty
					sx={{
						color: '#fff', // White text color
						'& .MuiSelect-select': {
							padding: '4px 6px', // Adjust padding inside the select input
							border: 'none', // Remove any internal border
							backgroundColor: 'transparent', // Make sure the background is transparent
							boxShadow: 'none', // Remove any box shadow if present
							paddingRight: '8px !important', // Override the padding-right applied by default
						},
						'& .MuiSelect-icon': {
							color: '#fff', // White arrow icon color
						},
					}}
					IconComponent={() => (
						<GlassBox
							onClick={(e) => {
								e.stopPropagation();
								// Trigger the Select dropdown by dispatching a click event
                console.log("Click!");
								e.currentTarget
									.closest('.MuiFormControl-root')
									.querySelector('.MuiSelect-select')
									.click();
							}}
							sx={{
								display: 'flex',
								alignItems: 'center',
								justifyContent: 'center',
								width: '35px',
								height: '25px',
								borderRadius: '50px',
							}}>
							<ArrowDropDown sx={{ color: '#fff', pointerEvents: 'none' }} />
						</GlassBox>
					)}
					MenuProps={{
						PaperProps: {
							sx: {
								backgroundColor: '#333', // Darker background for the dropdown
								color: '#fff', // White text color in dropdown
							},
						},
					}}>
					{options.map((option) => (
						<MenuItem
							key={option.value}
							value={option.value}
							sx={{ color: '#fff' }} // White text color for each option
						>
							{option.title}
						</MenuItem>
					))}
				</Select>
			</FormControl>
		</GlassBox>
	);
};
export const CustomTooltip = ({ title, brandOwner, children, props }) => {
	const theme = useTheme();
	const [tooltipVisible, setTooltipVisible] = useState(false);
	const [tooltipPosition, setTooltipPosition] = useState({ x: 0, y: 0 });

	const handleMouseEnter = (e) => {
		setTooltipVisible(true);
		updateTooltipPosition(e);
	};

	const handleMouseMove = (e) => {
		updateTooltipPosition(e);
	};

	const handleMouseLeave = () => {
		setTooltipVisible(false);
	};

	const updateTooltipPosition = (e) => {
		const offsetX = 0; // Offset to the right of the mouse
		const offsetY = 5; // Offset below the mouse
		const tooltipWidth = 300; // Width of the tooltip
		const mouseX = e.clientX;
		const mouseY = e.clientY;
		const windowWidth = window.innerWidth;

		const shiftFactor = (mouseX / windowWidth) * 2; // Range: -1 (left) to 1 (right)
		const xPosition = mouseX - (shiftFactor * tooltipWidth) / 2 + offsetX;
		const yPosition = mouseY + offsetY;

		setTooltipPosition({ x: xPosition, y: yPosition });
	};

	return (
		<Box
			onMouseEnter={handleMouseEnter}
			onMouseMove={handleMouseMove}
			onMouseLeave={handleMouseLeave}
			position="relative">
			{children}
			{tooltipVisible && (
				<Box
					position="fixed"
					left={tooltipPosition.x}
					top={tooltipPosition.y}
					bgcolor={theme.palette.grey[50]}
					borderRadius="20px"
					width="300px"
					p="1rem"
					border={`2px solid ${theme.palette.primary.main}`}
					boxShadow={3}
					zIndex={1000}>
					<Typography
						variant="h5"
						fontWeight="bold"
						color={theme.palette.text.light}
						style={{ textShadow: theme.shadows.heavy }}
						textAlign="left">
						{title}
					</Typography>
					<Typography
						variant="body1"
						color={theme.palette.text.light}
						style={{ textShadow: theme.shadows.heavy }}
						textAlign="left">
						{brandOwner}
					</Typography>
				</Box>
			)}
		</Box>
	);
};
