import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

export const api = createApi({
    baseQuery: fetchBaseQuery({ baseUrl: import.meta.env.VITE_BASE_URL }),
    reducerPath: "adminApi",
    tagTypes: [
    ],
    endpoints: (build) => ({
		registerPurchase: build.mutation({
			query: ({ projectId, purchase }) => ({
				url: `projects/registerPurchase/${projectId}`,
				method: 'POST',
				body: purchase,
				headers: {
					'Content-Type': 'application/json',
				},
				providesTags: ['Projects'],
			}),
		}),
		registerVisit: build.mutation({
			query: ({ projectId, visit }) => ({
				url: `projects/registerVisit/${projectId}`,
				method: 'POST',
				body: visit,
				headers: {
					'Content-Type': 'application/json',
				},
				providesTags: ['Projects'],
			}),
		}),
    }),
});

export const { useRegisterPurchaseMutation, useRegisterVisitMutation
} = api;