import { useDispatch, useSelector } from "react-redux";
import { usePostCreateFeedbackMutation } from "apis/feedbackApi";
import { Box, Typography, Backdrop, useTheme } from "@mui/material";
import { useState } from "react";
import { setFeedbackOpen } from "state";
import {
  CustomInputTextField,
  CustomColumnBox,
  CustomRowBox,
  CustomButton,
  GlassBox,
} from "components/CustomInteractors";
import { Close } from "@mui/icons-material";
import { RatingHeart } from "components/RatingHeart";

export const FeedbackPopup = () => {
  const token = useSelector((state) => state.global.token);
  const feedbackOpen = useSelector((state) => state.global.feedbackOpen);
  const dispatch = useDispatch();
  const theme = useTheme();
  const [createFeedback] = usePostCreateFeedbackMutation();

  const [feedback, setFeedback] = useState("");
  const [feedbackType, setFeedbackType] = useState("bug");
  const [rating, setRating] = useState(0);

  const projectId = import.meta.env.VITE_PROJECTID;

  const emptyAll = () => {
    setTitle("");
    setDescription("");
    setFeedbackType("bug");
    setRating(0);
  };
  const onSendFeedback = async () => {
    dispatch(setFeedbackOpen({ open: false }));
    const response = await createFeedback({
      token,
      feedback: {
        feedback: feedback,
        feedbackType: feedbackType,
        rating: rating,
        projectId: projectId,
        projectName: "LønnLyst"
      },
    }).unwrap();
    console.log(response);
    emptyAll();
  };
  const SetFeedback = (event) => {
    setFeedback(event.target.value);
  };
  const SetFeedbackType = (event) => {
    setFeedbackType(event.target.value);
  };
  const SetRating = (event) => {
    setRating(event.target.value);
  };
  return (
    <Box width="100%" height="100%">
      {feedbackOpen && (
        <Backdrop sx={{ zIndex: 50 }} open={true}>
          <GlassBox width="400px">
            <GlassBox
              p="1rem"
              display="flex"
              flexDirection="row"
              justifyContent="space-between"
              alignItems="center"
              sx={{ borderRadius: "0px" }}
            >
              <Typography
                textAlign="left"
                color={theme.palette.text.dark}
                variant="h3"
              >
                Feedback
              </Typography>
              <Box
                onClick={() => {
                  dispatch(setFeedbackOpen({ open: false }));
                }}
              >
                <Close />
              </Box>
            </GlassBox>
            <Box
              p=".5rem 1rem"
              display="flex"
              flexDirection="column"
              justifyContent="flex-start"
              alignItems="center"
              gap="1rem"
            >
              <CustomColumnBox alignItems="center" width="100%">
                <CustomInputTextField
                  label="Description"
                  onChange={SetFeedback}
                  placeholder=""
                  value={feedback}
                />
                <CustomRowBox>
                  <RatingHeart value={0} rating={rating} onClick={setRating} />
                  <RatingHeart value={1} rating={rating} onClick={setRating} />
                  <RatingHeart value={2} rating={rating} onClick={setRating} />
                  <RatingHeart value={3} rating={rating} onClick={setRating} />
                  <RatingHeart value={4} rating={rating} onClick={setRating} />
                </CustomRowBox>
                <CustomButton onClick={() => onSendFeedback()}>
                  Send
                </CustomButton>
              </CustomColumnBox>
            </Box>
          </GlassBox>
        </Backdrop>
      )}
    </Box>
  );
};
